@import url("https://fonts.googleapis.com/css2?family=Amaranth:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --hidden: false;
}
.hide {
  display: var(--hidden);
}

.btn-xs {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-5 py-2 px-3 border-slate-200 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-textarea {
  @apply placeholder-gray-400;
}

.form-select {
  @apply pr-10;
}

.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply text-sm text-gray-800 bg-white border;
}

/* styles.css */
.tab-transition {
  transition: border-bottom 0.3s ease-in-out;
}

.tab-panel-transition {
  transition: opacity 0.3s ease-in-out;
}

.btn,
.btn-lg,
.btn-sm,
.btn-xs,
.btn-chart {
  @apply font-medium text-sm inline-flex items-center dark:text-zinc-300 dark:border-zinc-700 border rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-3 py-2;
}

.btn-lg {
  @apply px-4 py-3;
}

.btn-sm {
  @apply px-2 py-1;
}
.btn-chart {
  @apply px-2 py-1 w-[100px] dark:bg-[#1a1a1a] dark:border-zinc-800 text-sm;
}

.btn-xs {
  @apply px-2 py-0.5;
}
.form-checkbox {
  @apply w-4 h-4 text-brand_50 rounded focus:ring-brand_50 dark:bg-zinc-800 bg-zinc-200;
}

.form-input {
  @apply border-slate-200 placeholder:opacity-40 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-textarea {
  @apply border-slate-200 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.form-search {
  @apply border-slate-200 focus:border-blue-600 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 dark:bg-[#1a1a1a];
}

.hover-transition {
  @apply shadow-lg z-20 hover:shadow-xl transition-all duration-300 ease-in-out hover:scale-105;
}
.circular-progress text {
  @apply dark:text-white;
}

.c {
  background-color: rgb(247 248 252);
}

body:not(.dark) .dark-unstyled-content {
  display: none;
}

/* Dark mode: Show dark-styled content */
body.dark .dark-unstyled-content {
  display: block;
}

.dark .react-loading-skeleton::after {
  background-image: linear-gradient(90deg, #1a1a1a, #171717, #1a1a1a);
}

.stats-gradient {
  background: linear-gradient(
    75.96deg,
    #f66a3e 13.64%,
    #f73753 46.53%,
    #d32471 78.88%
  );
}

.nav-shadow {
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px;
}
.dark .nav-shadow {
  box-shadow: none;
}

.mySwiper {
  padding: 0 1rem 4rem !important;
}

@media (min-width: 850px) {
  .mySwiper {
    padding-bottom: 6rem !important;
  }
}

.mySwiperInstructor {
  padding: 0 1rem 4rem !important;
}

@media (min-width: 850px) {
  .mySwiperInstructor {
    padding-bottom: 6rem !important;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(270deg, transparent 2%, #ee4b2b);
}

.youtube-modal {
  padding: 2em;
  display: flex;
  justify-content: center;
  text-align: center;
  /* box-shadow: 0 4px 8px #3a3a4429, 0 8px 16px #5a5b6a29; */
  align-items: center;
}

iframe {
  max-width: 100%;
}

.form-switch {
  @apply relative select-none;
  width: 44px;
}
.form-switch label {
  @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}
.form-switch label > span:first-child {
  @apply absolute block rounded-full;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  right: 50%;
  transition: all 0.15s ease-out;
}
.form-switch input[type="checkbox"]:checked + label {
  @apply bg-brand;
}
.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 22px;
}
.form-switch input[type="checkbox"]:disabled + label {
  @apply cursor-not-allowed bg-gray-100 border border-gray-200;
}
.form-switch input[type="checkbox"]:disabled + label > span:first-child {
  @apply bg-gray-400;
}

/* .theme-toggle {
  @apply p-2 rounded-lg border-brand_50 border-2
} */
.theme-toggle svg {
  @apply w-6 h-6 fill-brand stroke-brand;
}
.theme-toggle svg g g {
  @apply stroke-brand;
}

.btn-brand {
  @apply px-8 py-3 rounded-lg text-white bg-brand hover:bg-brand_50 w-full;
}
.btn-brand-2 {
  @apply rounded-lg relative inline-flex items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-2 border-l-2 border-r-2  active:border-brand active:shadow-none shadow-lg bg-gradient-to-tr from-brand to-brand_50 hover:from-brand_50 hover:to-brand_40  border-brand_60 text-white;
}

.dark .ql-editor {
  color: #f5f5f5;
}

.dark .ql-editor placeholder {
  color: #f5f5f5;
}

.dark .ql-toolbar.ql-snow {
  border: 2px solid #3f3f46 !important;
  background-color: #3f3f4600 !important;
}

.ql-snow.ql-toolbar button {
  height: 28px !important;
  width: 28px !important;
}
.dark .ql-container.ql-snow {
  border: 2px solid #3f3f46 !important;
}

.ql-container.ql-snow {
  border: 2px solid #e4e4e7 !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}
.ql-toolbar.ql-snow {
  border: 2px solid #e4e4e7 !important;
  background-color: #f5f5f5;
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px !important;
}

.ql-toolbar.ql-snow {
  font-family: "Public Sans";
}

.dark .ql-editor.ql-blank::before {
  color: #494949 !important;
}

.discussion-height {
  height: 150px !important;
}

.notes-height {
  height: 200px !important;
}

.vimeo-div[data-v-a8a349ba] {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  aspect-ratio: 16/9;
}

.overflow-hidden::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}
.overflow-hidden {
  scrollbar-width: none !important;
}

.premium-split {
  height: calc(100vh - 128px);
}

.action-sash-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.1);
}

.swiper-wrapper {
  transition-timing-function: linear;
}

.rich-editor-height {
  height: 150px !important;
}

.dark .css-13cymwt-control {
  background-color: #1a1a1a !important;
}
.dark .css-1nmdiq5-menu {
  background-color: #1a1a1a !important;
}

.dark .select__control--is-focused {
  background-color: #1a1a1a !important;
}

.dark .select__option.select__option--is-focused.css-d7l1ni-option {
  background-color: #111111;
  box-sizing: border-box;
  color: rgba(243, 243, 243, 0.918);
  cursor: default;
  font-family: Lato;
  line-height: 24px;
  padding: 8px 12px;
  user-select: none;
}

.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: 10em;
  height: 10em;
  background: #1f1f1f;
  -webkit-box-shadow: 3em 2.5em 0 0em #fff inset,
    rgba(255, 255, 255, 0.2) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.2) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.2) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -1em 10em 0 -4.5em;
  box-shadow: 3em 2.5em 0 0em #fff inset,
    rgba(255, 255, 255, 0.2) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.2) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.2) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.2) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.2) -1em 10em 0 -4.5em;
}

.sun {
  top: 4.5em;
  left: 20em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #facc15;
  -webkit-box-shadow: 3em 3em 0 5em #facc15 inset, 0 -5em 0 -2.7em #facc15,
    3.5em -3.5em 0 -3em #facc15, 5em 0 0 -2.7em #facc15,
    3.5em 3.5em 0 -3em #facc15, 0 5em 0 -2.7em #facc15,
    -3.5em 3.5em 0 -3em #facc15, -5em 0 0 -2.7em #facc15,
    -3.5em -3.5em 0 -3em #facc15;
  box-shadow: 3em 3em 0 5em #facc15 inset, 0 -5em 0 -2.7em #facc15,
    3.5em -3.5em 0 -3em #facc15, 5em 0 0 -2.7em #facc15,
    3.5em 3.5em 0 -3em #facc15, 0 5em 0 -2.7em #facc15,
    -3.5em 3.5em 0 -3em #facc15, -5em 0 0 -2.7em #facc15,
    -3.5em -3.5em 0 -3em #facc15;
}
